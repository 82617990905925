import { withPrefix } from 'gatsby'
import React, { SVGAttributes } from 'react'

export interface IconProps extends SVGAttributes<SVGElement> {
  id:
    | 'add'
    | 'addcircle_fill'
    | 'addcircle_outline'
    | 'arrow_down'
    | 'arrow_left'
    | 'arrow_right'
    | 'arrow_up'
    | 'bookmark'
    | 'calendar'
    | 'cart_0'
    | 'cart_1'
    | 'cart_2'
    | 'cart_3'
    | 'cart_4'
    | 'checklist'
    | 'chevron_down'
    | 'chevron_left'
    | 'chevron_right'
    | 'chevron_up'
    | 'clock'
    | 'close'
    | 'creditcard'
    | 'dollar'
    | 'double_chevron_up'
    | 'double_chevron_right'
    | 'edit'
    | 'error_outline'
    | 'eye_close'
    | 'eye_open'
    | 'facebook'
    | 'filter_fill'
    | 'filter_outline'
    | 'grid'
    | 'help'
    | 'home'
    | 'info_fill'
    | 'info_outline'
    | 'instagram'
    | 'loading'
    | 'location'
    | 'logout'
    | 'mail_subject'
    | 'menu'
    | 'minus'
    | 'minuscircle_fill'
    | 'minuscircle_outline'
    | 'order_clipboard'
    | 'product_list'
    | 'promos'
    | 'refresh'
    | 'search'
    | 'sort'
    | 'star_fill'
    | 'star_outline'
    | 'store'
    | 'success'
    | 'trash'
    | 'truck'
    | 'user'
    | 'warning_fill'
    | 'warning_outline'
    | 'play_button'
    | 'heart_outline'
    | 'heart_filled'
    | 'broken_heart_filled'
    | 'list'
    | 'download'
    | 'box_outline'
    | 'bottle'
    | 'phone'
    | 'paper_clip'
    | 'close_circle'
    | 'red_alert'
    | 'tag'
    | 'snowflake'
    | 'whatsapp'
    | 'time_clock'
    | 'achievement'
    | 'warningCircle'
    | 'archiveBox'
    | 'checks'
  size?: number | string
  sizeWidth?: number | string
  sizeHeight?: number | string
  sizeUnit?: string
  color?: string
  onClick?: (e: any) => void
}

const getFillColor = (color?: string): string => {
  if (!color) return 'currentColor'
  // evaluar fallback de tailwind theme
  // if (props.theme.colors[props.fillColor]) return props.theme.colors[props.fillColor]
  return color
}

const Icon = (props: IconProps) => {
  const color = getFillColor(props.color)
  return (
    <svg
      {...props}
      style={{
        fill: color,
        height: `${props.sizeHeight ?? props.size ?? 32}${props.sizeUnit ?? 'px'}`,
        width: `${props.sizeWidth ?? props.size ?? 32}${props.sizeUnit ?? 'px'}`,
      }}
    >
      <use xlinkHref={withPrefix(`sprite.svg#icon-${props.id}`)} />
    </svg>
  )
}

export default Icon
